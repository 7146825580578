








































































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import GeneralApi from "@/networks/GeneralApi";
import store from "@/store";

@Component({})
export default class Notifications extends Vue {
  private notifications = [];
  private page = 1;

  mounted() {
    this.notification();
  }

  public async notification(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().notification({
        page: `${this.page}`
      });
      this.notifications = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
}
